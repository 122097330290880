import { useNavigate } from 'react-router-dom';
import './blog-preview-box.scss';

export default function BlogPreviewBox({ lang, lastBlog, article }) {
  const navigate = useNavigate();

  return (
    <div
      className={`border-4 border-black rounded-lg filter grayscale-[.6]
        overflow-hidden
        hover:cursor-pointer hover:grayscale-0
        blog-preview-box
        ${lastBlog ? 'blog-preview-box--highlighted' : ''}`}
      onClick={() => navigate(`/novyny/${article.slug}`)}
    >
      <div className="flex flex-row justify-between items-center
        w-full min-h-[76px] pt-3 px-4 pb-4 bg-black
        blog-preview-box__title">
        <h2 className="text-white font-aux font-bold uppercase">
          {article.title}
        </h2>
        <span className="text-white font-base font-normal">
          {new Date(article.created_at).toLocaleString(
            lang === 'en' ? 'en-US' : 'uk-UA',
            { month: 'long', day: 'numeric', year: 'numeric' }
          )}
        </span>
      </div>
      <div className="bg-white rounded-b">
        <div
          className={`flex-auto h-[240px] bg-center bg-cover
            bg-no-repeat bg-origin-border bg-clip-border
            ${lastBlog ? 'border-b-4 border-black' : 'h-[300px]'}`}
          style={{ backgroundImage: `url('${article.thumbnail}')` }}
          alt={article.title}
        />
        <p className="px-8 py-6 text-black font-base font-normal
          leading-8 bg-white rounded-lg md:leading-7
          blog-preview-box__excerpt">{article.excerpt}</p>
      </div>
    </div>
  );
}