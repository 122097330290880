import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import api from '../../api';
import BlogPreviewBox from './blog-preview-box';
import { texts } from '../../texts.js';

export default function Blog() {
  const lang = useOutletContext();
  const [highlightedArticle, setHighlightedArticle] = useState([]);
  const [olderArticles, setOlderArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await api.get('blog/');
        const filteredBlogs = response.data.filter((_) => _.language === lang);
        const highlightedBlog = filteredBlogs[0];
        const olderBlogs = filteredBlogs.slice(1);
        setHighlightedArticle(highlightedBlog);
        setOlderArticles(olderBlogs);
      } catch (error) {
        if (!error.response) {
          console.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, [lang]);

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {!isLoading
        && (
          <section className="relative flex flex-col min-h-[calc(100vh-366px)]
            py-20 px-4 bg-violet
            xl:space-x-10 xl:flex-row 2xl:p-20">
            <div className="noise-overlay"></div>
            <div className="z-10 flex flex-col items-stretch space-y-2
              w-full p-4
              md:space-y-10 xl:w-1/2 xl:p-0">
              <h1 className="my-4 text-black font-aux text-4xl
                font-extrabold text-left sm:text-5xl md:my-0">
                {texts.blog.latestBlogTitle[lang]}:
              </h1>
              <BlogPreviewBox
                lang={lang}
                lastBlog={true}
                key={highlightedArticle?.id}
                article={highlightedArticle}
              >
              </BlogPreviewBox>
            </div>
            <div className="z-10 flex flex-col items-stretch space-y-2
              w-full p-4
              md:space-y-10 xl:w-1/2 xl:p-0">
              <h1 className="my-4 text-black font-aux text-4xl
                font-extrabold text-left sm:text-5xl md:my-0">
                {texts.blog.olderBlogsTitle[lang]}:
              </h1>
              <div className="z-10 flex flex-col items-stretch
                sm:flex-row sm:flex-wrap">
                {olderArticles.map((_, i) => (
                  <BlogPreviewBox
                    lang={lang}
                    lastBlog={false}
                    key={_.id}
                    article={_}
                  >
                  </BlogPreviewBox>
                ))}
              </div>
            </div>
          </section>
        )
      }
    </>
  );
}