import './what-we-do-box.scss';

export default function WhatWeDoBox({
  id,
  title,
  img1,
  img2,
  img3,
  text
}) {
  return (
    <div className="pt-10" id={id}>
      <div className="relative flex w-full h-[600px]
        bg-white border-4 border-black rounded-lg
        what-we-do"
      >
        <div
          className="w-1/3 h-full border-r-4 border-black rounded-l
            bg-center bg-cover bg-no-repeat bg-origin-border
            bg-clip-border
            what-we-do__photo"
          style={{ backgroundImage: `url('${img1}')` }}
        />
        <div
          className="w-1/3 h-full border-r-4 border-black bg-center bg-cover
            bg-no-repeat bg-origin-border bg-clip-border
            what-we-do__photo"
          style={{ backgroundImage: `url('${img2}')` }}
        />
        <div
          className="w-1/3 h-full rounded-r bg-center bg-cover
            bg-no-repeat bg-origin-border bg-clip-border
            what-we-do__photo"
          style={{ backgroundImage: `url('${img3}')` }}
        />
        <div className="bottom-0 p-4 flex flex-col
          justify-start bg-white
          border-r-4 border-t-4 border-black rounded-tr-lg rounded-bl
          what-we-do__text">
          <h3 className="text-black font-base text-xl font-extrabold
            tracking-tight">
            {title}
          </h3>
          <p
            className="mt-4 text-black font-base font-normal"
            dangerouslySetInnerHTML={{ __html: text }}
          >
          </p>
        </div>
      </div>
    </div>
  );
}
