import './team-member.scss';

export default function TeamMember({ img, text, heroMark=false }) {
  return (
    <div className="flex flex-col items-center space-y-4">
      <div
        className="relative w-[200px] h-[200px] bg-center bg-cover
          bg-no-repeat bg-origin-border bg-clip-border
          border-4 border-black rounded-lg overflow-hidden
          team-member-photo"
        style={{ backgroundImage: `url('${img}')` }}
      >
        {heroMark
          ? <div className="hero-mark"></div>
          : ''}
      </div>
      <div className="flex flex-col">
        <p className="text-black font-base text-2xl font-semibold
          tracking-tight team-member-name">
          {text}
        </p>
      </div>
    </div>
  );
}