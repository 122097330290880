import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import api from '../../api';
import './photo.scss';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

export default function Photo() {
  const lang = useOutletContext();
  const [photo, setPhoto] = useState({});
  const [photos, setPhotos] = useState({});
  const [nextPhoto, setNextPhoto] = useState({});
  const [prevPhoto, setPrevPhoto] = useState({});
  const params = useParams();
  
  useEffect(() => {
    const fetchPhoto = async () => {
      try {
        const response = await api.get(`gallery/${params.id}`);
        setPhoto(response.data);
      }
      catch (error) {
        if (!error.response) {
          console.error(error.message);
        }
      };
    }

    fetchPhoto();
  }, [params.id]);

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await api.get('gallery/list');
        if (photo) {
          const index = response.data.findIndex((_) => _.id === photo.id);

          if (index !== -1) {
            // set next
            if (index !== response.data.length - 1) {
              setNextPhoto(response.data[index + 1].slug);
            } else {
              setNextPhoto(response.data[0].slug);
            }
  
            // set prev
            if (index !== 0) {
              setPrevPhoto(response.data[index - 1].slug);
            } else {
              setPrevPhoto(response.data[response.data.length - 1].slug);
            }
          }
        }
        setPhotos(response.data);
      }
      catch (error) {
        if (!error.response) {
          console.error(error.message);
        }
      };
    }

    fetchPhotos();
  }, [photo]);

  return (
    <>
      <section className="relative flex flex-col items-center
        min-h-[calc(100vh-366px)] p-4 bg-gray-dark md:p-8 photo-wrapper">
        <div className="noise-overlay"></div>
        <div className="z-10 flex w-full h-full justify-between items-stretch">
          <div className="self-center flex justify-center items-center
            link-wrapper">
            <a
              className="p-4 text-3xl border-4 border-gray-light rounded-full
                group hover:bg-gray-light"
              href={`/galereya/${prevPhoto}`}
            >
              <BsChevronLeft
                className="relative left-[-1px] stroke-1 stroke-gray-light
                  group-hover:stroke-gray-dark"
              />
            </a>
          </div>
          <div className="flex-auto mx-8 border-4 border-gray-light rounded-lg
            photo">
            <div
              className="bg-center bg-cover bg-no-repeat
                bg-origin-border bg-clip-border rounded"
              style={{
                height: `${photo.height}px`,
                maxHeight: '80vh',
                backgroundImage: `url('${photo.photo}')`
              }}
            />
          </div>
          <div className="self-center flex justify-center items-center
            link-wrapper">
            <a
              className="p-4 text-3xl border-4 border-gray-light rounded-full
                group hover:bg-gray-light"
              href={`/galereya/${nextPhoto}`}
            >
              <BsChevronRight
                className="relative right-[-1px] stroke-1 stroke-gray-light
                  group-hover:stroke-gray-dark"
              />
            </a>
          </div>
        </div>
        <div className="z-10">
          <p
            className="mt-4 text-gray-light font-base text-lg font-semibold
              text-center leading-relaxed md:mt-6 md:text-xl"
            dangerouslySetInnerHTML={{
              __html: lang === 'en'
                ? photo.description_en
                : photo.description_ua }}
          ></p>
        </div>
      </section>
    </>
  );
}