export const texts = {
  header: {
    title: {
      en: 'Public organization "Piatykhatky-BAM"',
      ua: 'Громадська організація "П\'ятихатки-БАМ"'
    },
    openMobileMenuSrOnly: {
      en: 'Open mobile menu',
      ua: 'Відкрити мобільне меню'
    },
    navBtnMain: {
      en: 'Home',
      ua: 'Головна'
    },
    navBtnWhatWeDo: {
      en: 'What we do',
      ua: 'Що ми робимо'
    },
    navBtnBlog: {
      en: 'News',
      ua: 'Новини'
    },
    navBtnGallery: {
      en: 'Gallery',
      ua: 'Галерея'
    },
    navBtnDonate: {
      en: 'Support us',
      ua: 'Як допомогти'
    },
    navBtnAboutUs: {
      en: 'About us',
      ua: 'Про нас'
    },
  },
  home: {
    heroText: {
      en: 'We are a registered volunteer organization that has been carrying out its humanitarian activities since the end of <b>February 2022</b>. Our organization has more than <b>15</b> active members. We focus on distributing humanitarian aid in the Kharkiv and Donetsk regions, supporting socially and medically vulnerable people, organizing events for children affected by the military conflict, distributing food for dogs and cats, and taking care of them.<br/><br/>Would you like to support our activities?',
      ua: 'Ми - зареєстрована волонтерська організація, яка здійснює свою гуманітарну діяльність з кінця <b>лютого 2022 р.</b> Наша організація налічує понад <b>15</b> активних членів. Ми зосереджені на роздачі гуманітарної допомоги в Харківській та Донецькій областях, підтримці соціально та медично незахищених людей, організації заходів для дітей, які постраждали від військового конфлікту, роздачі кормів для собак та котів, а також догляді за ними.<br/><br/>Бажаєте підтримати нашу діяльність?'
    },
    btnSupportUs: {
      en: 'Click here',
      ua: 'Натисніть тут'
    },
    whatWeDoTitle: {
      en: 'WHAT WE DO',
      ua: 'ЩО МИ РОБИМО'
    },
    whatWeDoCardText1: {
      en: 'Distribution of humanitarian aid in Kharkiv and Donetsk regions',
      ua: 'Розподіл гуманітарної допомоги в Харківській і Донецькій областях'
    },
    whatWeDoCardText2: {
      en: 'Evacuations of people and animals',
      ua: 'Евакуація людей і тварин'
    },
    whatWeDoCardText3: {
      en: 'Support for socially vulnerable groups of population in Piatykhatki district',
      ua: 'Підтримка соціально незахищених верств населення в П\'ятихатському районі'
    },
    whatWeDoCardText4: {
      en: 'Activities for children',
      ua: 'Заходи для дітей'
    },
    whatWeDoCardText5: {
      en: 'Animal care',
      ua: 'Догляд за тваринами'
    },
    whatWeDoCardText6: {
      en: 'Assistance in eliminating the consequences of shelling',
      ua: 'Допомога в ліквідації наслідків обстрілів'
    },
    aboutUsTitle: {
      en: 'About us',
      ua: 'Про нас'
    },
    aboutUsText: {
      en: 'Our organization naturally began to form on February 25, 2022 in response to the current social situation in the Piatykhatky microdistrict and its residents, which rapidly worsened as a result of the Russian military invasion of Ukraine. By that time, there were about 500 people left in our neighborhood who could not leave.<br/><br/>As of mid-March 2022, it already had more than a dozen members who actively helped their fellow citizens and neighbors, especially the elderly, people with limited mobility, mothers with children, who for several months remained cut off from traditional services and from their social and family ties.',
      ua: 'Наша організація закономірно почала формуватися 25 лютого 2022 року у відповідь на поточну соціальну ситуацію в мікрорайоні П\'ятихатки та його мешканців, яка стрімко погіршилася внаслідок російського військового вторгнення в Україну. На той час у нашому мікрорайоні залишилося близько 500 осіб, які не змогли виїхати.<br/><br/>На середину березня 2022 року в ньому вже налічувалося більше десятка членів, які активно допомагали своїм співгромадянам і сусідам, особливо людям похилого віку, маломобільним людям, матерям з дітьми, які протягом декількох місяців залишалися відрізаними від традиційних послуг і від своїх соціальних і сімейних зв\'язків.'
    },
    aboutUsHighlightCaption1: {
      en: 'people have had humanitarian aid delivered to',
      ua: 'людям доставлена матеріальна гуманітарна допомога'
    },
    aboutUsHighlightCaption2: {
      en: 'active members',
      ua: 'активних членів'
    },
    aboutUsHighlightCaption3: {
      en: 'people evacuated',
      ua: 'людей евакуйовано'
    },
    aboutUsOurTeamTitle: {
      en: 'Our team',
      ua: 'Наша команда'
    },
    aboutUsTina: {
      en: 'Valentina Pyrozhenko',
      ua: 'Валентина Пироженко'
    },
    aboutUsAndrii: {
      en: 'Andrii Tagaiev',
      ua: 'Андрій Тагаєв'
    },
    aboutUsSerhii: {
      en: 'Serhii Shalyhin',
      ua: 'Сергій Шалигін'
    },
    aboutUsAlice: {
      en: 'Alice Mirovská',
      ua: 'Аліце Міровска'
    },
    aboutUsMoreLinkBtn: {
      en: 'Find out more about us',
      ua: 'Дізнатися більше про нас'
    },
    aboutUsGalleryLinkBtn: {
      en: 'Go to gallery',
      ua: 'Перейти до галереї'
    }
  },
  whatWeDo: {
    title: {
      en: 'WHAT WE DO',
      ua: 'ЩО МИ РОБИМО'
    },
    titleCard1: {
      en: 'We deliver humanitarian aid to Kharkiv and Donetsk regions',
      ua: 'Доставляємо гуманітарну допомогу в Харківську та Донецьку області'
    },
    textCard1: {
      en: 'We deliver food supplies and hygiene kits to villages that have recently been liberated or still are being shelled by russian military. Electricity, gas and water supply are cut off in these places. There are no shops, pharmacies, or medical services. Since we are a small local organization that operates even in the smallest localities, we can go where other organizations can\'t.<br/><br/>We have delivered humanitarian aid to more than <b>1,100</b> people in the Kharkiv region. We regularly deliver aid to villages where more people live. In total, we distributed medicines worth about <b>380,000</b> UAH.',
      ua: 'Ми доставляємо продукти харчування та гігієнічні набори в села, які нещодавно були звільнені або все ще перебувають під обстрілами російської армії. У цих місцях відключено електро-, газо- та водопостачання. Там немає магазинів, аптек і медичної допомоги. Оскільки ми є невеликою місцевою організацією, яка працює навіть у найменших населених пунктах, ми можемо дістатися туди, куди інші організації не можуть.<br/><br/>Ми доставили матеріальну гуманітарну допомогу більш ніж <b>1 100</b> людям у Харківській області. Регулярно розвозимо допомогу в села, де проживає більше людей. Загалом ми роздали медикаментів на суму близько <b>380 000</b> гривень.'
    },
    titleCard2: {
      en: 'Evacuations of civilians from the frontlines',
      ua: 'Евакуація цивільного населення з лінії фронту'
    },
    textCard2: {
      en: 'On the basis of personal or intermediary agreements, we evacuate people from the active combat zone and deliver them to safer places - to relatives, friends or dormitories for internally displaced people.<br/><br/>We have already evacuated more than <b>400</b> people and <b>120</b> animals from the border and frontline territories.',
      ua: 'На основі особистих або посередницьких домовленостей ми евакуюємо людей із зони активних бойових дій і доставляємо їх у безпечніші місця - до родичів, друзів або в гуртожитки для внутрішньо переміщених осіб.<br/><br/>Ми вже евакуювали понад <b>400</b> людей та <b>120</b> тварин з прикордонних та прифронтових територій.'
    },
    titleCard3: {
      en: 'We support people from vulnerable groups in the Piatykhatki district',
      ua: 'Підтримуємо людей з вразливих груп у П\'ятихатському районі'
    },
    textCard3: {
      en: 'We deliver food and hygiene kits to people with disabilities of movement and orientation, people (especially mothers with children) who strive in unfavorable social conditions.<br/><br/>Our list includes addresses where people with limited mobility live, to whom we regularly deliver food and hygiene products.',
      ua: 'Доставляємо продуктові та гігієнічні набори людям з обмеженими можливостями пересування та орієнтації, людям (особливо матерям з дітьми), які перебувають у несприятливих соціальних умовах.<br/><br/>У нашому списку є адреси, де проживають маломобільні люди, яким ми регулярно розвозимо продукти харчування та засоби гігієни.'
    },
    titleCard4: {
      en: 'We organize events for children',
      ua: 'Організовуємо заходи для дітей'
    },
    textCard4: {
      en: 'We are trying to make the lives of children whose lives were affected by the military conflict more pleasant and diverse. We organize sports and creative competitions for children in the Pyatikhatki microdistrict. We cover children who live in villages of the Kharkiv region, remote from any opportunities for organized leisure, often without means of communication and electricity. We organize children\'s parties, sports and art events for them, bring sweets, fruits and other things that they lack.',
      ua: 'Ми намагаємося зробити життя дітей, чиє життя зачепив військовий конфлікт, більш приємним і різноманітним. Ми організовуємо спортивні та творчі конкурси для дітей у мікрорайоні П\'ятихатки. Ми охоплюємо дітей, які живуть у селах Харківської області, віддалених від будь-яких можливостей організованого дозвілля, часто без зв\'язку та електрики. Ми організовуємо для них дитячі свята, спортивні та мистецькі заходи, привозимо солодощі, фрукти та інші речі, яких їм не вистачає.'
    },
    titleCard5: {
      en: 'We take care of animals',
      ua: 'Дбаємо про тварин'
    },
    textCard5: {
      en: 'We evacuate animals from villages that are being shelled, deliver food to places where abandoned animals are found, and support animal owners in social need by providing free food.<br/><br/>We regularly deliver food for about <b>250</b> dogs and <b>500</b> cats.',
      ua: 'Ми евакуюємо тварин з обстрілюваних сіл, доставляємо їжу в місця, де знаходять покинутих тварин, підтримуємо власників тварин, які перебувають у соціальній нужді, надаючи безкоштовну їжу.<br/><br/>Регулярно розвозимо їжу для близько <b>250</b> собак і <b>500</b> котів.'
    },
    titleCard6: {
      en: 'We help eliminate the consequences of shelling',
      ua: 'Допомагаємо усувати наслідки обстрілів'
    },
    textCard6: {
      en: 'We help residents of war-affected areas seal up window and door openings damaged by shelling or pressure waves, and help clear debris.<br/><br/>We helped close more than <b>800</b> window openings, more than <b>600 m&sup2;</b> of non-residential premises, and also removed tons of debris and other remains from shelling.',
      ua: 'Ми допомагаємо жителям постраждалих від війни районів заклеювати віконні та дверні прорізи, пошкоджені обстрілами або напірними хвилями, допомагаємо розчищати уламки та деревину.<br/><br/>Ми допомогли закрити понад <b>800</b> віконних прорізів, понад <b>600 m&sup2;</b> нежитлових приміщень, а також вивезли тонни уламків та іншого сміття від обстрілів.'
    },
  },
  blog: {
    latestBlogTitle: {
      en: 'LATEST BLOG',
      ua: 'ОСТАННІЙ БЛОГ'
    },
    olderBlogsTitle: {
      en: 'OLDER BLOGS',
      ua: 'СТАРШІ БЛОГИ'
    }
  },
  donate: {
    accountInfoTitle: {
      en: 'PO "Piatykhatky-BAM" banking details:',
      ua: 'Реквізити ГО "П\'ятихатки-БАМ":'
    },
    baseAccountNumbers: {
      en: '<b>USD</b> UA543515330000026008025906218<br/><b>EUR</b> UA683515330000026008025901989',
      ua: '<b>USD</b> UA543515330000026008025906218<br/><b>EUR</b> UA683515330000026008025901989'
    },
    recipientName: {
      en: '<b>Recipient\'s name:</b> П\'ЯТИХАТКИ-БАМ ГО',
      ua: '<b>Найменування отримувача:</b> П\'ЯТИХАТКИ-БАМ ГО'
    },
    recipientCode: {
      en: '<b>Recipient\'s code:</b> 44701467',
      ua: '<b>Код отримувача:</b> 44701467'
    },
    iban: {
      en: '<b>IBAN:</b> UA303515330000026008025903158',
      ua: '<b>IBAN:</b> UA303515330000026008025903158'
    },
    bankName: {
      en: '<b>Bank name:</b> ХАРКIВСЬКЕ ГРУ АТ КБ "ПРИВАТБАНК"',
      ua: '<b>Назва банку:</b> ХАРКIВСЬКЕ ГРУ АТ КБ "ПРИВАТБАНК"'
    },
  },
  aboutUs: {
    aboutUsTitle: {
      en: 'About us',
      ua: 'Про нас'
    },
    aboutUsText1: {
      en: 'Our organization naturally began to form on February 25, 2022 in response to the current social situation in the Piatykhatky microdistrict and its residents, which rapidly worsened as a result of the Russian military invasion of Ukraine. By that time, there were about 500 people left in our neighborhood who could not leave.',
      ua: 'Наша організація закономірно почала формуватися 25 лютого 2022 року у відповідь на поточну соціальну ситуацію в мікрорайоні П\'ятихатки та його мешканців, яка стрімко погіршилася внаслідок російського військового вторгнення в Україну. На той час у нашому мікрорайоні залишилося близько 500 осіб, які не змогли виїхати.'
    },
    aboutUsText2: {
      en: 'As of mid-March 2022, it already had more than a dozen members who actively helped their fellow citizens and neighbors, especially the elderly, people with limited mobility, mothers with children, who for several months remained cut off from traditional services and from their social and family ties.',
      ua: 'На середину березня 2022 року в ньому вже налічувалося більше десятка членів, які активно долучалися до допомоги своїм співгромадянам і сусідам, особливо літнім людям, людям з обмеженою мобільністю та орієнтацією, матерям з дітьми, які протягом кількох місяців залишалися відрізаними як від традиційних послуг, так і від своїх соціальних і родинних зв\'язків.'
    },
    aboutUsText3: {
      en: 'At that time, we were mainly engaged in preparing and distributing hot lunches, distributing food packages, delivering food and hygiene products to people with limited mobility, as well as issuing basic medicines that were not available at that time.',
      ua: 'У той час ми в основному займалися приготуванням і роздачею гарячих обідів, видачею продуктових наборів, доставкою продуктів харчування та засобів гігієни маломобільним людям, а також видачею базових ліків, які на той час були недоступні.'
    },
    aboutUsText4: {
      en: 'We transported people to and from hospitals, transported them to relatives, and so on.',
      ua: 'Ми перевозили людей до лікарень та з лікарень, перевозили їх до родичів тощо.'
    },
    aboutUsText5: {
      en: 'Over time, we expanded our activities to include work on eliminating the consequences of shelling the buildings of our microdistrict - closing windows and entrances, clearing rubble, etc.',
      ua: 'Згодом ми розширили нашу діяльність, включивши в неї роботу з ліквідації наслідків обстрілів у будинках нашого мікрорайону - закривали вікна та під\'їзди, розчищали завали тощо.'
    },
    aboutUsText6: {
      en: 'We continued to evacuate people and animals from areas where active military operations were conducted to safer places.',
      ua: 'Ми продовжували евакуацію людей і тварин з районів, де велися активні бойові дії, в більш безпечні місця.'
    },
    aboutUsText7: {
      en: 'As conditions and the situation in our area have partially stabilized, our geographical priorities in providing humanitarian assistance have changed. We have started sending aid to villages in border areas and areas that have recently been liberated, as well as to areas where active fighting is still taking place. Where infrastructure, social services, and paid services are not functioning. At the same time, it is home to a large number of people from vulnerable and at-risk groups who are not able to meet their basic needs on their own.',
      ua: 'Коли умови і ситуація в нашому районі частково стабілізувалися, наші географічні пріоритети в наданні гуманітарної допомоги змінилися. Ми почали спрямовувати допомогу в села в прикордонних районах і районах, які нещодавно були звільнені, а також у райони, де все ще тривають активні бойові дії. Там, де не функціонує інфраструктура, соціальні служби та платні послуги. Водночас там проживає велика кількість людей із вразливих груп населення та груп ризику, які не в змозі самостійно задовольнити свої базові потреби.'
    },
    aboutUsText8: {
      en: 'We continue this work. We are trying to deliver humanitarian aid to places where other actors cannot reach. It\'s worth going to a place where only three grandmothers and five dogs live. Because every life has its own price and every person matters.',
      ua: 'Ми продовжуємо цю роботу. Ми намагаємося доставити гуманітарну допомогу туди, куди інші суб\'єкти не можуть дістатися. Це варте того, щоб їхати туди, де живуть лише три бабусі та п\'ять собак. Тому що кожне життя має свою ціну і кожна людина має значення.'
    },
    ourTeamTitle: {
      en: 'Our team',
      ua: 'Наша команда'
    },
    aboutUsTinaName: {
      en: 'Valentina Pyrozhenko',
      ua: 'Валентина Пироженко'
    },
    aboutUsTinaText1: {
      en: 'Head of the organization',
      ua: 'Директорка організації'
    },
    aboutUsTinaText2: {
      en: 'Cooperation, donors, local governments, humanitarian aid/evacuation orders',
      ua: 'Співпраця, донори, органи місцевого самоврядування, гуманітарна допомога/замовлення на евакуацію'
    },
    aboutUsTinaTel: {
      en: 'Tel: +380 68 425 86 11, +380 66 449 12 76',
      ua: 'Тел: +380 68 425 86 11, +380 66 449 12 76'
    },
    aboutUsTinaEmail: {
      en: 'Email: <a href="mailto:rottweiler.sh@gmail.com">rottweiler.sh@gmail.com</a>',
      ua: 'Email: <a href="mailto:rottweiler.sh@gmail.com">rottweiler.sh@gmail.com</a>'
    },
    aboutUsAndrejName: {
      en: 'Andrii Tagaiev',
      ua: 'Андрій Тагаєв'
    },
    aboutUsAndrejText1: {
      en: 'Humanitarian aid coordinator',
      ua: 'Координатор з питань надання гуманітарної допомоги'
    },
    aboutUsAndrejText2: {
      en: 'Ordering humanitarian aid, evacuation',
      ua: 'Замовлення гуманітарної допомоги, евакуація'
    },
    aboutUsAndrejTel: {
      en: 'Tel: +380 95 733 70 78',
      ua: 'Тел: +380 95 733 70 78'
    },
    aboutUsAndrejEmail: {
      en: '',
      ua: ''
    },
    aboutUsSergejName: {
      en: 'Serhii Shalyhin',
      ua: 'Сергій Шалигін'
    },
    aboutUsSergejText1: {
      en: '16.10.1970 – 19.9.2023',
      ua: '16.10.1970 – 19.9.2023'
    },
    aboutUsSergejText2: {
      en: 'Eternal glory to the hero',
      ua: 'Вічна слава герою'
    },
    aboutUsSergejTel: {
      en: '',
      ua: ''
    },
    aboutUsSergejEmail: {
      en: '',
      ua: ''
    },
    aboutUsAliceName: {
      en: 'Alice Mirovská',
      ua: 'Аліце Міровска'
    },
    aboutUsAliceText1: {
      en: 'To communicate in English and/or Czech languages',
      ua: 'Для спілкування англійською/чеською мовою'
    },
    aboutUsAliceText2: {
      en: '',
      ua: ''
    },
    aboutUsAliceTel: {
      en: 'Tel.: +420 731 489 761 (Telegram, Whats App, Signal)',
      ua: 'Тел.: +420 731 489 761 (Telegram, Whats App, Signal)'
    },
    aboutUsAliceEmail: {
      en: 'Email: <a href="mailto:alice.mirovska@seznam.cz">alice.mirovska@seznam.cz</a>',
      ua: 'Email: <a href="mailto:alice.mirovska@seznam.cz">alice.mirovska@seznam.cz</a>'
    },
  },
  footer: {
    navBtnMain: {
      en: 'Home',
      ua: 'Головна'
    },
    navBtnWhatWeDo: {
      en: 'What we do',
      ua: 'Що ми робимо'
    },
    navBtnBlog: {
      en: 'News',
      ua: 'Новини'
    },
    navBtnGallery: {
      en: 'Gallery',
      ua: 'Галерея'
    },
    navBtnDonate: {
      en: 'Support us',
      ua: 'Як допомогти'
    },
    navBtnAboutUs: {
      en: 'About us',
      ua: 'Про нас'
    },
    copyrightName: {
      en: 'Public organization "Piatykhatky-BAM"',
      ua: 'Громадська організація "П\'ятихатки-БАМ"'
    },
    copyrightAllRightsProtected: {
      en: 'All rights protected.',
      ua: 'Всi права захищено.'
    },
    copyrightDetails: {
      en: 'The usage of any materials on this site, images and texts, as well as automated copying of the information presented on site by any programs and scripts without an explicit written permission from HO PYATYHATKI-BAM is prohibited.',
      ua: 'Використання матеріалів сайту, зображень та текстів, а також автоматизоване копіювання інформації сайту будь-якими програмами без письмового дозволу ГО "П\'ятихатки-БАМ" заборонено.'
    },
  },
}