import { Link } from 'react-router-dom';
import { FaFacebookSquare, FaTelegram } from 'react-icons/fa';
import { BsInstagram } from 'react-icons/bs';
import { texts } from '../texts.js';

export default function Footer({ lang }) {
  return (
    <footer>
      <div className="flex flex-col justify-center items-start py-8 px-8 !pb-4
        space-y-9 bg-white md:items-center md:py-24">
        <nav
          className="flex flex-col flex-wrap space-x-0 space-y-2
            md:flex-row md:flex-nowrap md:space-x-12 md:space-y-0"
          aria-label="Footer"
        >
          <Link
            className="text-gray-light tracking-tight hover:text-gray-dark"
            to={'/'}
          >
            {texts.footer.navBtnMain[lang]}
          </Link>
          <Link
            className="text-gray-light tracking-tight hover:text-gray-dark"
            to={'what-we-do'}
          >
            {texts.footer.navBtnWhatWeDo[lang]}
          </Link>
          <Link
            className="text-gray-light tracking-tight hover:text-gray-dark"
            to={'novyny'}
          >
            {texts.footer.navBtnBlog[lang]}
          </Link>
          <Link
            className="text-gray-light tracking-tight hover:text-gray-dark"
            to={'galereya'}
          >
            {texts.footer.navBtnGallery[lang]}
          </Link>
          <Link
            className="text-gray-light tracking-tight hover:text-gray-dark"
            to={'pidtrymaty'}
          >
            {texts.footer.navBtnDonate[lang]}
          </Link>
          <Link
            className="text-gray-light tracking-tight hover:text-gray-dark"
            to={'kontakty'}
          >
            {texts.footer.navBtnAboutUs[lang]}
          </Link>
        </nav>
        <div className="flex space-x-9">
          <Link
            className="text-2xl"
            to={'https://www.facebook.com/5hatkibam'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare
              className="fill-gray-light hover:fill-gray-dark"
            />
          </Link>
          <Link
            className="text-2xl"
            to={'https://www.instagram.com/piatykhatky_bam/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsInstagram
              className="fill-gray-light hover:fill-gray-dark"
            />
          </Link>
          <Link
            className="text-2xl"
            to={'#'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegram
              className="fill-gray-light hover:fill-gray-dark"
            />
          </Link>
        </div>
        <div className="flex flex-col items-center space-y-4 md:space-y-0">
          <p className="text-gray-light text-sm tracking-tight">
            {texts.footer.copyrightName[lang]} &copy; {new Date().getFullYear()}. {texts.footer.copyrightAllRightsProtected[lang]}
          </p>
          <p className="text-gray-light text-sm tracking-tight sm:text-center">
            {texts.footer.copyrightDetails[lang]}
          </p>
        </div>
      </div>
    </footer>
  );
}