import { useOutletContext } from 'react-router-dom';
import { texts } from '../../texts.js';
import WhatWeDoBox from './what-we-do-box';
import imgWhatWeDo11 from '../../assets/photos/what-we-do/01/01.JPEG';
import imgWhatWeDo12 from '../../assets/photos/what-we-do/01/02.jpeg';
import imgWhatWeDo13 from '../../assets/photos/what-we-do/01/03.JPEG';
import imgWhatWeDo21 from '../../assets/photos/what-we-do/02/01.jpeg';
import imgWhatWeDo22 from '../../assets/photos/what-we-do/02/02.jpeg';
import imgWhatWeDo23 from '../../assets/photos/what-we-do/02/03.jpeg';
import imgWhatWeDo31 from '../../assets/photos/what-we-do/03/01.jpg';
import imgWhatWeDo32 from '../../assets/photos/what-we-do/03/02.JPG';
import imgWhatWeDo33 from '../../assets/photos/what-we-do/03/03.JPEG';
import imgWhatWeDo41 from '../../assets/photos/what-we-do/04/01.jpeg';
import imgWhatWeDo42 from '../../assets/photos/what-we-do/04/02.jpeg';
import imgWhatWeDo43 from '../../assets/photos/what-we-do/04/03.jpeg';
import imgWhatWeDo51 from '../../assets/photos/what-we-do/05/01.JPG';
import imgWhatWeDo52 from '../../assets/photos/what-we-do/05/02.HEIC';
import imgWhatWeDo53 from '../../assets/photos/what-we-do/05/03.jpeg';
import imgWhatWeDo61 from '../../assets/photos/what-we-do/06/01.JPG';
import imgWhatWeDo62 from '../../assets/photos/what-we-do/06/02.jpeg';
import imgWhatWeDo63 from '../../assets/photos/what-we-do/06/03.jpeg';


export default function WhatWeDo() {
  const lang = useOutletContext();

  return (
    <>
      <section className="relative flex flex-col items-center p-4 bg-pink
        md:p-20">
        <div className="noise-overlay"></div>
        <div className="z-10 w-full">
          <h1 className="mt-8 text-black font-aux text-4xl
            font-extrabold text-center sm:text-5xl">
            {texts.whatWeDo.title[lang]}
          </h1>
          <WhatWeDoBox
            id="we-deliver-humanitarian-aid-to-kharkiv-and-donetsk-regions"
            title={texts.whatWeDo.titleCard1[lang]}
            img1={imgWhatWeDo11}
            img2={imgWhatWeDo12}
            img3={imgWhatWeDo13}
            text={texts.whatWeDo.textCard1[lang]}
          />
          <WhatWeDoBox
            id="evacuations-of-civilians-from-the-frontlines"
            title={texts.whatWeDo.titleCard2[lang]}
            img1={imgWhatWeDo21}
            img2={imgWhatWeDo22}
            img3={imgWhatWeDo23}
            text={texts.whatWeDo.textCard2[lang]}
          />
          <WhatWeDoBox
            id="we-support-people-from-vulnerable-groups-in-the-piatykhatki-district"
            title={texts.whatWeDo.titleCard3[lang]}
            img1={imgWhatWeDo31}
            img2={imgWhatWeDo32}
            img3={imgWhatWeDo33}
            text={texts.whatWeDo.textCard3[lang]}
          />
          <WhatWeDoBox
            id="we-organize-events-for-children"
            title={texts.whatWeDo.titleCard4[lang]}
            img1={imgWhatWeDo41}
            img2={imgWhatWeDo42}
            img3={imgWhatWeDo43}
            text={texts.whatWeDo.textCard4[lang]}
          />
          <WhatWeDoBox
            id="we-take-care-of-animals"
            title={texts.whatWeDo.titleCard5[lang]}
            img1={imgWhatWeDo51}
            img2={imgWhatWeDo52}
            img3={imgWhatWeDo53}
            text={texts.whatWeDo.textCard5[lang]}
          />
          <WhatWeDoBox
            id="we-help-eliminate-the-consequences-of-shelling"
            title={texts.whatWeDo.titleCard6[lang]}
            img1={imgWhatWeDo61}
            img2={imgWhatWeDo62}
            img3={imgWhatWeDo63}
            text={texts.whatWeDo.textCard6[lang]}
          />
        </div>
      </section>
    </>
  );
}
