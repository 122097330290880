import { useOutletContext } from 'react-router-dom';
import { texts } from '../../texts.js';

export default function Donate() {
  const lang = useOutletContext();

  return (
    <>
      <section
        className="flex flex-col items-center min-h-[calc(100vh-366px)] p-4
          bg-red md:p-14"
      >
        <div className="noise-overlay"></div>
        <div className="z-10 w-full md:w-1/2">
          <h6 className="text-black text-2xl font-semibold tracking-tight">
            {texts.donate.accountInfoTitle[lang]}
          </h6>
          <p
            className="my-6 text-black"
            dangerouslySetInnerHTML={{ __html: texts.donate.baseAccountNumbers[lang] }}
          ></p>
          <p
            className="my-2 text-black"
            dangerouslySetInnerHTML={{ __html: texts.donate.recipientName[lang] }}
          ></p>
          <p
            className="my-2 text-black"
            dangerouslySetInnerHTML={{ __html: texts.donate.recipientCode[lang] }}
          ></p>
          <p
            className="my-2 text-black"
            dangerouslySetInnerHTML={{ __html: texts.donate.iban[lang] }}
          ></p>
          <p
            className="my-2 text-black"
            dangerouslySetInnerHTML={{ __html: texts.donate.bankName[lang] }}
          ></p>
        </div>
      </section>
    </>
  );
}