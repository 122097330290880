import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import './blog-detail.scss';

export default function BlogDetail() {
  const [article, setArticle] = useState({});
  const params = useParams();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await api.get(`blog/${params.id}`);
        setArticle(response.data);
      }
      catch (error) {
        if (!error.response) {
          console.error(error.message);
        }
      };
    }

    fetchArticle();
  }, [params.id]);

  return (
    <>
      <section className="relative min-h-[calc(100vh-366px)] flex justify-center
        p-4 bg-violet md:p-20">
        <div className="noise-overlay"></div>
        <div className="z-10 w-1/2 blog-wrapper">
        <div className="flex flex-col border-4 border-black bg-white
          rounded-lg">
          <h1 className="p-4 text-white font-aux text-4xl
            font-extrabold text-center uppercase bg-black blog-title
            sm:text-5xl">
            {article.title}
          </h1>
          <div
            className="w-full mx-auto p-4 blog-content sm:p-16"
            dangerouslySetInnerHTML={{ __html: article.content }}
          ></div>
        </div>
        </div>
      </section>
    </>
  );
}