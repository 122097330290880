import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as Dialog from '@radix-ui/react-dialog';
import { RxHamburgerMenu, RxCross2 } from 'react-icons/rx';
import { FaFacebookSquare, FaTelegram } from 'react-icons/fa';
import { BsInstagram } from 'react-icons/bs';
import './Header.scss';
import logo from './logo.png';
import { texts } from '../texts.js';

export default function Header({ fgColor, bgColor, lang, setLang }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [pathname]);

  useEffect(() => {
    document.body.className = 'overflow-auto';

    if (mobileMenuOpen) {
      document.body.className = 'overflow-hidden';
    } else {
      document.body.className = 'overflow-auto';
    }
  }, [mobileMenuOpen]);

  return (
    <header className={`relative z-10 flex flex-row w-full h-[77px] bg-${bgColor}
      md:flex-col md:h-auto`}>
      <div className="noise-overlay"></div>
      <div className={`flex flex-row justify-center items-center py-4
        text-${fgColor} font-aux text-6xl font-extrabold text-center uppercase
        tracking-tight leading-6
        border-b-4 border-${fgColor} cursor-default
        header-title`}>
        <Link
          className="mx-2 2xl:mr-12"
          to={'/'}
        >
          <span className="sr-only">Головна</span>
          <img
            className="cursor-pointer drop-shadow-[0_0_1px_rgba(0,0,0,1)]
            grayscale-[.6] hover:grayscale-0"
            src={logo}
            alt="5hatki logo"
          />
        </Link>
        {texts.header.title[lang]}
        <div className={`absolute right-5 top-5 z-10 hidden items-stretch
          border-2 border-${fgColor} lg:flex
          lang-selection-btns`}>
          <button
            className={`px-2 py-3 text-xl font-extrabold
              leading-3 tracking-tighter border-r-2 border-${fgColor}
              hover:text-${bgColor} hover:bg-${fgColor}
              ${lang === 'ua'
                ? `text-${bgColor} bg-${fgColor}`
                : `text-${fgColor} bg-${bgColor}`}`}
            onClick={() => setLang('ua')}
          >UA</button>
          <button
            className={`px-2 py-3 text-xl font-extrabold
              leading-3 tracking-tighter
              hover:text-${bgColor} hover:bg-${fgColor}
              ${lang === 'en'
                ? `text-${bgColor} bg-${fgColor}`
                : `text-${fgColor} bg-${bgColor}`}`}
            onClick={() => setLang('en')}
          >EN</button>
        </div>
      </div>
      <div className={`flex items-center px-4 border-b-4 border-${fgColor}
        md:px-0 nav-menu-wrapper`}>
        <nav
          className="relative flex justify-center w-full"
          aria-label="Global"
        >
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">
                {texts.header.openMobileMenuSrOnly[lang]}
              </span>
              <RxHamburgerMenu
                className={`h-6 w-6 stroke-1 stroke-${fgColor}`}
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="hidden self-center lg:flex">
            <Link
              className={`px-8
                text-${fgColor} font-aux text-3xl font-extrabold uppercase
                border-l-4 border-${fgColor}
                hover:text-${bgColor} hover:bg-${fgColor}
                nav-menu-button`}
              to={'/'}
            >
              {texts.header.navBtnMain[lang]}
            </Link>
            <Link
              className={`px-8
                text-${fgColor} font-aux text-3xl font-extrabold uppercase
                border-l-4 border-${fgColor}
                hover:text-${bgColor} hover:bg-${fgColor}
                nav-menu-button`}
              to={'/what-we-do'}
            >
              {texts.header.navBtnWhatWeDo[lang]}
            </Link>
            <Link
              className={`px-8
                text-${fgColor} font-aux text-3xl font-extrabold uppercase
                border-l-4 border-${fgColor}
                hover:text-${bgColor} hover:bg-${fgColor}
                nav-menu-button`}
              to={'novyny'}
            >
              {texts.header.navBtnBlog[lang]}
            </Link>
            <Link
              className={`px-8
                text-${fgColor} font-aux text-3xl font-extrabold uppercase
                border-l-4 border-${fgColor}
                hover:text-${bgColor} hover:bg-${fgColor}
                nav-menu-button`}
              to={'galereya'}
            >
              {texts.header.navBtnGallery[lang]}
            </Link>
            <Link
              className={`px-8
                text-${fgColor} font-aux text-3xl font-extrabold uppercase
                border-l-4 border-${fgColor}
                hover:text-${bgColor} hover:bg-${fgColor}
                nav-menu-button`}
              to={'pidtrymaty'}
            >
              {texts.header.navBtnDonate[lang]}
            </Link>
            <Link
              className={`px-8
                text-${fgColor} font-aux text-3xl font-extrabold uppercase
                border-x-4 border-${fgColor}
                hover:text-${bgColor} hover:bg-${fgColor}
                nav-menu-button`}
              to={'kontakty'}
            >
              {texts.header.navBtnAboutUs[lang]}
            </Link>
          </div>
          <div className="absolute right-0 hidden h-full lg:flex">
            <Link
              className={`flex justify-center items-center px-1
                text-3xl border-l-4 border-${fgColor}
                group hover:bg-${fgColor}
                nav-menu-social`}
              to={'https://www.facebook.com/5hatkibam'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookSquare
                className={`fill-${fgColor} group-hover:fill-${bgColor}`}
              />
            </Link>
            <Link
              className={`flex justify-center items-center px-1
                text-3xl border-l-4 border-${fgColor}
                group hover:bg-${fgColor}
                nav-menu-social`}
              to={'https://www.instagram.com/piatykhatky_bam/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsInstagram
                className={`fill-${fgColor} group-hover:fill-${bgColor}`}
              />
            </Link>
            <Link
              className={`flex justify-center items-center px-1
                text-3xl border-l-4 border-${fgColor}
                group hover:bg-${fgColor}
                nav-menu-social`}
              to={'#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTelegram
                className={`fill-${fgColor} group-hover:fill-${bgColor}`}
              />
            </Link>
          </div>
        </nav>
      </div>
      <Dialog.Root
        className="lg:hidden"
        open={mobileMenuOpen}
        onOpenChange={setMobileMenuOpen}
      >
        <Dialog.Portal>
          <Dialog.Content
            className={`fixed top-0 left-0 z-20 h-full w-full bg-${bgColor}`}
          >
            <div className="flex items-center pt-[18px] px-5">
              <nav
                className="relative flex flex-col justify-center w-full
                  space-y-3"
                aria-label="Global"
              >
                <Link
                  className={`text-${fgColor} font-aux text-3xl font-bold uppercase`}
                  to={'/'}
                >
                  {texts.header.navBtnMain[lang]}
                </Link>
                <Link
                  className={`text-${fgColor} font-aux text-3xl font-bold uppercase`}
                  to={'/what-we-do'}
                >
                  {texts.header.navBtnWhatWeDo[lang]}
                </Link>
                <Link
                  className={`text-${fgColor} font-aux text-3xl font-bold uppercase`}
                  to={'novyny'}
                >
                  {texts.header.navBtnBlog[lang]}
                </Link>
                <Link
                  className={`text-${fgColor} font-aux text-3xl font-bold uppercase`}
                  to={'galereya'}
                >
                  {texts.header.navBtnGallery[lang]}
                </Link>
                <Link
                  className={`text-${fgColor} font-aux text-3xl font-bold uppercase`}
                  to={'pidtrymaty'}
                >
                  {texts.header.navBtnDonate[lang]}
                </Link>
                <Link
                  className={`text-${fgColor} font-aux text-3xl font-bold uppercase`}
                  to={'kontakty'}
                >
                  {texts.header.navBtnAboutUs[lang]}
                </Link>
              </nav>
            </div>
            <div className="absolute bottom-0 z-30 flex
              w-full py-4 px-5 space-x-8">
              <Link
                className="flex justify-center items-center text-3xl"
                to={'https://www.facebook.com/5hatkibam'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookSquare className={`fill-${fgColor} group-hover:fill-${bgColor}`} />
              </Link>
              <Link
                className="flex justify-center items-center text-3xl"
                to={'https://www.instagram.com/piatykhatky_bam/'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsInstagram className={`fill-${fgColor} group-hover:fill-${bgColor}`} />
              </Link>
              <Link
                className="flex justify-center items-center text-3xl"
                to={'#'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegram className={`fill-${fgColor} group-hover:fill-${bgColor}`} />
              </Link>
              <div className={`flex items-stretch !ml-auto
                border-2 border-${fgColor}`}>
                <button
                  className={`px-2 py-3 text-xl font-extrabold
                    leading-3 tracking-tighter border-r-2 border-${fgColor}
                    hover:text-${bgColor} hover:bg-${fgColor}
                    ${lang === 'ua'
                      ? `text-${bgColor} bg-${fgColor}`
                      : `text-${fgColor} bg-${bgColor}`}`}
                  onClick={() => setLang('ua')}
                >UA</button>
                <button
                  className={`px-2 py-3 text-xl font-extrabold
                    leading-3 tracking-tighter
                    hover:text-${bgColor} hover:bg-${fgColor}
                    ${lang === 'en'
                      ? `text-${bgColor} bg-${fgColor}`
                      : `text-${fgColor} bg-${bgColor}`}`}
                  onClick={() => setLang('en')}
                >EN</button>
              </div>
            </div>
            <Dialog.Close
              className="absolute top-5 right-3 inline-flex
                items-center justify-center rounded-full p-1
                focus:outline-none"
            >
              <RxCross2 className={`h-6 w-6 stroke-1 stroke-${fgColor}`} />
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </header>
  );
}