import './team-member.scss';

export default function TeamMember({
  img,
  name,
  text1,
  text2,
  tel,
  email,
  heroMark=false
}) {
  return (
    <div className="flex items-start">
      <div
        className="relative flex-auto shrink-0
          w-[200px] max-w-[200px] h-[200px]
          bg-center bg-cover bg-no-repeat bg-origin-border bg-clip-border
          border-4 border-black rounded-lg overflow-hidden
          team-member-photo"
        style={{ backgroundImage: `url('${img}')` }}
      >
        {heroMark
          ? <div className="hero-mark"></div>
          : ''}
      </div>
      <div className="flex flex-col ml-4 md:ml-8">
        <p className="mb-2 text-black font-base text-2xl font-semibold
          tracking-tight team-member-name">
          {name}
        </p>
        <p className="mb-2 text-black font-base text-base
          tracking-tight md:text-lg team-member-text">
          {text1}
        </p>
        <p className="mb-2 text-black font-base text-base
          tracking-tight md:text-lg team-member-text">
          {text2}
        </p>
        <p
          className="mb-2 text-black font-base text-base
            tracking-tight md:text-lg team-member-text"
          dangerouslySetInnerHTML={{ __html: tel }}
        />
        <p
          className="mb-2 text-black font-base text-base
            tracking-tight md:text-lg team-member-text"
          dangerouslySetInnerHTML={{ __html: email }}
        />
      </div>
    </div>
  );
}