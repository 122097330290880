import { HashLink } from 'react-router-hash-link';

export default function WhatWeDoBox({ img, text, url }) {
  return (
    <HashLink className="relative flex w-full h-[600px] mb-10
      bg-white border-4 border-black rounded-lg
      filter grayscale-[.6] hover:cursor-pointer hover:grayscale-0"
      smooth to={url}>
      <div
        className="w-full h-full rounded bg-center bg-cover
          bg-no-repeat bg-origin-border bg-clip-border"
        style={{ backgroundImage: `url('${img}')` }}
      />
      <div className={`absolute bottom-0 p-4 flex flex-col
        w-full
        justify-start bg-black`}>
        <h3 className="text-white font-base text-xl font-extrabold
          tracking-tight">
          {text}
        </h3>
      </div>
    </HashLink>
  );
}