import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import './styles/index.scss';
import Root from './routes/root';
import ErrorPage from './error-page';
import Home from './routes/home/home';
import WhatWeDo from './routes/what-we-do/what-we-do';
import Blog from './routes/blog/blog';
import BlogDetail from './routes/blog/blog-detail';
import Gallery from './routes/gallery/gallery';
import Photo from './routes/gallery/photo';
import Donate from './routes/donate/donate';
import Contact from './routes/contact/contact';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'what-we-do',
        element: <WhatWeDo />,
      }, 
      {
        path: 'novyny',
        element: <Blog />,
      },
      {
        path: 'novyny/:id',
        element: <BlogDetail />,
      },
      {
        path: 'galereya',
        element: <Gallery />,
      },
      {
        path: 'galereya/:id',
        element: <Photo />,
      },
      {
        path: 'pidtrymaty',
        element: <Donate />,
      },
      {
        path: 'kontakty',
        element: <Contact />,
      },
    ]
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);