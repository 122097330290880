import { useOutletContext } from 'react-router-dom';
import imgTina from '../../assets/photos/team/tina.jpg';
import imgAndrej from '../../assets/photos/team/andrej.jpg';
import imgSergej from '../../assets/photos/team/sergej.jpg';
import imgAlice from '../../assets/photos/team/alice.png';
import { texts } from '../../texts.js';
import TeamMember from './team-member';
import './contact.scss';

export default function Contact() {
  const lang = useOutletContext();

  return (
    <>
      <section
        className="relative flex flex-col items-center p-4 bg-green md:p-24"
      >
        <div className="noise-overlay"></div>
        <div className="flex flex-col items-center z-10">
          <h1
            className="mt-4 text-black font-aux text-5xl font-extrabold
              text-center uppercase md:m-0"
            dangerouslySetInnerHTML={{ __html: texts.aboutUs.aboutUsTitle[lang] }}
          >
          </h1>
          <div
            className="w-1/2 mt-12 text-black font-base text-base font-semibold
              leading-loose about-us__main-text md:text-lg"
            dangerouslySetInnerHTML={{ __html: texts.aboutUs.aboutUsText1[lang] }}
          />
          <div
            className="w-1/2 mt-8 text-black font-base text-base font-semibold
              leading-loose about-us__main-text md:text-lg"
            dangerouslySetInnerHTML={{ __html: texts.aboutUs.aboutUsText2[lang] }}
          />
          <div
            className="w-1/2 mt-8 text-black font-base text-base font-semibold
              leading-loose about-us__main-text md:text-lg"
            dangerouslySetInnerHTML={{ __html: texts.aboutUs.aboutUsText3[lang] }}
          />
          <div
            className="w-1/2 mt-4 text-black font-base text-base font-semibold
              leading-loose about-us__main-text md:text-lg"
            dangerouslySetInnerHTML={{ __html: texts.aboutUs.aboutUsText4[lang] }}
          />
          <div
            className="w-1/2 mt-4 text-black font-base text-base font-semibold
              leading-loose about-us__main-text md:text-lg"
            dangerouslySetInnerHTML={{ __html: texts.aboutUs.aboutUsText5[lang] }}
          />
          <div
            className="w-1/2 mt-4 text-black font-base text-base font-semibold
              leading-loose about-us__main-text md:text-lg"
            dangerouslySetInnerHTML={{ __html: texts.aboutUs.aboutUsText6[lang] }}
          />
          <div
            className="w-1/2 mt-16 text-black font-base text-base font-semibold
              leading-loose about-us__main-text md:text-lg"
            dangerouslySetInnerHTML={{ __html: texts.aboutUs.aboutUsText7[lang] }}
          />
          <div
            className="w-1/2 mt-16 text-black font-base text-base font-semibold
              leading-loose about-us__main-text md:text-lg"
            dangerouslySetInnerHTML={{ __html: texts.aboutUs.aboutUsText8[lang] }}
          />
          <div className="w-3/6 mt-16 about-us__our-team">
            <h6 className="mb-8 text-black font-base text-3xl font-bold text-center
              tracking-tight uppercase">
              {texts.aboutUs.ourTeamTitle[lang]}
            </h6>
            <div className="flex flex-col py-8 px-4 space-y-8 lg:space-y-16">
              <TeamMember
                img={imgTina}
                name={texts.aboutUs.aboutUsTinaName[lang]}
                text1={texts.aboutUs.aboutUsTinaText1[lang]}
                text2={texts.aboutUs.aboutUsTinaText2[lang]}
                tel={texts.aboutUs.aboutUsTinaTel[lang]}
                email={texts.aboutUs.aboutUsTinaEmail[lang]}
              />
              <TeamMember
                img={imgAndrej}
                name={texts.aboutUs.aboutUsAndrejName[lang]}
                text1={texts.aboutUs.aboutUsAndrejText1[lang]}
                text2={texts.aboutUs.aboutUsAndrejText2[lang]}
                tel={texts.aboutUs.aboutUsAndrejTel[lang]}
                email={texts.aboutUs.aboutUsAndrejEmail[lang]}
              />
              <TeamMember
                img={imgSergej}
                name={texts.aboutUs.aboutUsSergejName[lang]}
                text1={texts.aboutUs.aboutUsSergejText1[lang]}
                text2={texts.aboutUs.aboutUsSergejText2[lang]}
                tel={texts.aboutUs.aboutUsSergejTel[lang]}
                email={texts.aboutUs.aboutUsSergejEmail[lang]}
                heroMark={true}
              />
              <TeamMember
                img={imgAlice}
                name={texts.aboutUs.aboutUsAliceName[lang]}
                text1={texts.aboutUs.aboutUsAliceText1[lang]}
                text2={texts.aboutUs.aboutUsAliceText2[lang]}
                tel={texts.aboutUs.aboutUsAliceTel[lang]}
                email={texts.aboutUs.aboutUsAliceEmail[lang]}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}