import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Root() {
  const [lang, setLang] = useState(
    ['uk', 'uk-UA', 'ru', 'ru-RU'].includes(
      window.navigator.userLanguage || window.navigator.language
    ) ? 'ua' : 'en'
  );
  const location = useLocation();
  let fgColor, bgColor;

  switch(location.pathname) {
    case '/':
      fgColor = 'black';
      bgColor = 'blue';
      break;
    case '/what-we-do':
      fgColor = 'black';
      bgColor = 'pink';
      break;
    case '/galereya':
      fgColor = 'gray-light';
      bgColor = 'gray-dark';
      break;
    case '/pidtrymaty':
      fgColor = 'black';
      bgColor = 'red';
      break;
    case '/kontakty':
      fgColor = 'black';
      bgColor = 'green';
      break;
    default:
      fgColor = 'black';
      bgColor = 'white';
      break;
  }

  if (location.pathname.startsWith('/novyny')) {
    fgColor = 'black';
    bgColor = 'violet';
  }
  if (location.pathname.startsWith('/galereya')) {
    fgColor = 'gray-light';
    bgColor = 'gray-dark';
  }

  return (
    <>
      <Header
        fgColor={fgColor}
        bgColor={bgColor}
        lang={lang}
        setLang={setLang}
      />
      <Outlet context={lang} />
      <Footer lang={lang} />
    </>
  );
}