import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../api';
import './gallery.scss';

export default function Gallery() {
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await api.get('gallery/');
        setPhotos(response.data);
      } catch (error) {
        if (!error.response) {
          console.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchPhotos();
  }, []);

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {!isLoading
        && (
          <section className="relative flex flex-col min-h-[calc(100vh-366px)]
            p-8 bg-gray-dark md:p-20">
            <div className="noise-overlay"></div>
            <div className="z-10 flex flex-wrap">
              {photos.map((_) => (
                <Link
                  className="h-[300px] mb-10
                    border-4 border-black rounded-lg
                    filter grayscale-[.6]
                    overflow-hidden
                    hover:cursor-pointer hover:grayscale-0
                    photo-preview-box"
                  key={_.id}
                  to={`/galereya/${_.slug}`}
                >
                  <div className="bg-white rounded">
                    <div
                      className="flex-auto h-[300px] bg-center bg-cover
                        bg-no-repeat bg-origin-border bg-clip-border"
                      style={{ backgroundImage: `url('${_.photo}')` }}
                      alt={_.name}
                    />
                  </div>
                </Link>
              ))}
            </div>
          </section>
        )
      }
    </>
  );
}