import { Link, useOutletContext } from 'react-router-dom';
import WhatWeDoBox from './what-we-do-box';
import './home.scss';
import imgHero from '../../assets/photos/home/hero.HEIC';
import imgWhatWeDo1 from '../../assets/photos/home/what-we-do/01.jpeg';
import imgWhatWeDo2 from '../../assets/photos/home/what-we-do/02.jpeg';
import imgWhatWeDo3 from '../../assets/photos/home/what-we-do/03.jpg';
import imgWhatWeDo4 from '../../assets/photos/home/what-we-do/04.jpeg';
import imgWhatWeDo5 from '../../assets/photos/home/what-we-do/05.jpeg';
import imgWhatWeDo6 from '../../assets/photos/home/what-we-do/06.JPG';
import imgAboutUs1 from '../../assets/photos/home/about_us_2.png';
import imgAboutUs2 from '../../assets/photos/home/about_us_3.JPEG';
import imgAboutUs3 from '../../assets/photos/home/about_us_4.jpeg';
import imgTina from '../../assets/photos/team/tina.jpg';
import imgAndrej from '../../assets/photos/team/andrej.jpg';
import imgSergej from '../../assets/photos/team/sergej.jpg';
import imgAlice from '../../assets/photos/team/alice.png';
import { texts } from '../../texts.js';
import TeamMember from './team-member';

export default function Home() {
  const lang = useOutletContext();
  const aboutUsPhotos = [imgAboutUs1, imgAboutUs2, imgAboutUs3];

  return (
    <>
      
      {/* --- HERO SECTION --- */}

      <section className="relative min-h-[780px] p-4 bg-blue md:p-20">
        <div className="noise-overlay"></div>
        <div className="relative z-10 grid grid-cols-1 grid-rows-2 h-full
          border-4 border-black rounded-lg bg-white
          lg:grid-cols-6 lg:grid-rows-1">
          <div className="order-last flex flex-col p-4 border-t-4 border-black
            xl:col-span-2 lg:col-span-3 lg:order-first sm:p-16
            lg:border-r-4 lg:border-t-0">
            <p
              className="w-full text-black font-base leading-relaxed
                md:text-xl md:leading-relaxed"
              dangerouslySetInnerHTML={{ __html: texts.home.heroText[lang] }}
            >
            </p>
            <Link
              className="block self-center w-auto mt-4 px-7 py-3
                text-white font-base font-regular text-lg rounded-sm bg-black
                hover:opacity-80 md:mt-6 md:py-4 md:text-3xl"
              to={'pidtrymaty'}
            >
              {texts.home.btnSupportUs[lang]}
            </Link>
          </div>
          <div
            className="bg-center bg-cover bg-no-repeat
              bg-origin-border bg-clip-border
              xl:col-span-4 lg:col-span-3
              hero-image"
            style={{ backgroundImage: `url('${imgHero}')` }}
          />
        </div>
      </section>

      {/* --- WHAT WE DO SECTION --- */}

      <section className="relative flex flex-col justify-center items-center
        p-4 bg-pink md:p-12 xl:p-24">
        <div className="noise-overlay"></div>
        <h1 className="m-4 text-black font-aux text-5xl font-extrabold
          text-center">
          {texts.home.whatWeDoTitle[lang]}
        </h1>
        <div className="relative z-10 grid grid-cols-1 grid-rows-6
          w-full mt-4
          md:mt-8 md:gap-4 lg:gap-10
          lg:grid-cols-3 lg:grid-rows-2 2xl:w-5/6 xl:mt-16 2xl:gap-20">
          <WhatWeDoBox
            img={imgWhatWeDo1}
            text={texts.home.whatWeDoCardText1[lang]}
            url="what-we-do#we-deliver-humanitarian-aid-to-kharkiv-and-donetsk-regions"
          />
          <WhatWeDoBox
            img={imgWhatWeDo2}
            text={texts.home.whatWeDoCardText2[lang]}
            url="what-we-do#evacuations-of-civilians-from-the-frontlines"
          />
          <WhatWeDoBox
            img={imgWhatWeDo3}
            text={texts.home.whatWeDoCardText3[lang]}
            url="what-we-do#we-support-people-from-vulnerable-groups-in-the-piatykhatki-district"
          />
          <WhatWeDoBox
            img={imgWhatWeDo4}
            text={texts.home.whatWeDoCardText4[lang]}
            url="what-we-do#we-organize-events-for-children"
          />
          <WhatWeDoBox
            img={imgWhatWeDo5}
            text={texts.home.whatWeDoCardText5[lang]}
            url="what-we-do#we-take-care-of-animals"
          />
          <WhatWeDoBox
            img={imgWhatWeDo6}
            text={texts.home.whatWeDoCardText6[lang]}
            url="what-we-do#we-help-eliminate-the-consequences-of-shelling"
          />
        </div>
      </section>

      {/* --- ABOUT US SECTION --- */}

      <section className="relative flex flex-col justify-center items-center
        p-8 bg-green md:p-12 xl:p-24
        hp-about-us">
        <div className="noise-overlay"></div>
        <div className="flex flex-col items-center z-10">
          <h1
            className="mt-4 text-black font-aux text-5xl font-extrabold
              text-center uppercase md:m-0"
            dangerouslySetInnerHTML={{ __html: texts.home.aboutUsTitle[lang] }}
          >
          </h1>
          <div
            className="w-1/2 mt-12 text-black font-base text-base font-semibold
              leading-loose hp-about-us__main-text md:text-lg"
            dangerouslySetInnerHTML={{ __html: texts.home.aboutUsText[lang] }}
          >
          </div>
          <div className="flex flex-row justify-evenly items-stretch
            w-1/2 mt-8
            bg-white border-4 border-black rounded-lg
            highlights-box">
            <div className="flex-auto basis-1/3 flex flex-col
              justify-start items-center p-4 border-r-4 border-black md:p-8">
              <h6 className="text-black font-base text-3xl font-extrabold
                tracking-tight">
                &gt; 1 100
              </h6>
              <p className="flex-grow flex items-center mt-2
                text-black font-base text-center">
                {texts.home.aboutUsHighlightCaption1[lang]}
              </p>
            </div>
            <div className="flex-auto basis-1/3 flex flex-col
              justify-start items-center p-4 border-r-4 border-black md:p-8">
              <h6 className="text-black font-base text-3xl font-extrabold
                tracking-tight">
                &gt; 15
              </h6>
              <p className="flex-grow flex items-center mt-2
                text-black font-base text-center">
                {texts.home.aboutUsHighlightCaption2[lang]}
              </p>
            </div>
            <div className="flex-auto basis-1/3 flex flex-col
              justify-start items-center p-4 md:p-8">
              <h6 className="text-black font-base text-3xl font-extrabold
                tracking-tight">
                &gt; 400
              </h6>
              <p className="flex-grow flex items-center mt-2
                text-black font-base text-center">
                {texts.home.aboutUsHighlightCaption3[lang]}
              </p>
            </div>
          </div>
          <div className="w-3/6 mt-16 hp-about-us__our-team">
            <h6 className="text-black font-base text-3xl font-bold text-center
              tracking-tight uppercase">
              {texts.home.aboutUsOurTeamTitle[lang]}
            </h6>
            <div className="grid grid-cols-2 grid-rows-1 gap-y-10 mt-12 mb-8
              sm:gap-y-20">
              <TeamMember
                img={imgTina}
                text={texts.home.aboutUsTina[lang]}
              />
              <TeamMember
                img={imgAndrej}
                text={texts.home.aboutUsAndrii[lang]}
              />
              <TeamMember
                img={imgSergej}
                text={texts.home.aboutUsSerhii[lang]}
                heroMark={true}
              />
              <TeamMember
                img={imgAlice}
                text={texts.home.aboutUsAlice[lang]}
              />
            </div>
          </div>
          <div className="mt-8 hp-about-us__link-wrapper">
            <Link
              className="block self-center w-auto px-7 py-3
                text-white font-base font-regular text-lg rounded-sm bg-black
                hover:opacity-80 md:py-4 md:text-3xl"
              to={'kontakty'}
            >
              {texts.home.aboutUsMoreLinkBtn[lang]}
            </Link>
          </div>
          <div className="flex flex-row w-2/3 mt-20
            hp-about-us__photos">
            {aboutUsPhotos.map((_, i) => (
              <Link
                className="flex-auto h-[300px] mr-16 mb-10
                  border-4 border-black rounded-lg
                  filter grayscale-[.6]
                  overflow-hidden
                  last:mr-0
                  hover:cursor-pointer hover:grayscale-0"
                key={i}
                to={'galereya'}
              >
                <div className="bg-white rounded">
                  <div
                    className="h-[300px] bg-center bg-cover
                      bg-no-repeat bg-origin-border bg-clip-border"
                    style={{ backgroundImage: `url('${_}')` }}
                    alt=""
                  />
                </div>
              </Link>
            ))}
          </div>
          <div className="mt-4 hp-about-us__link-wrapper">
            <Link
              className="block self-center w-auto px-7 py-3
                text-white font-base font-regular text-lg rounded-sm bg-black
                hover:opacity-80 md:py-4 md:text-3xl"
              to={'galereya'}
            >
              {texts.home.aboutUsGalleryLinkBtn[lang]}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
